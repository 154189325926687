import * as React from "react";

export default function ProjektdetailHeader({Endkunde, Kurzbeschreibung}) {
    return (
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">

                <div>
                    <h1 className="text-2xl font-bold text-gray-900">{Endkunde}</h1>
                    <p className="text-medium font-medium text-gray-600">
                        {Kurzbeschreibung}
                    </p>
                </div>
            </div>
        </div>
    )
}