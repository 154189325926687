import * as React from "react";

export default function Auslastung({auslastung}) {
    if( auslastung === "Full time")
        return (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                {auslastung}
            </p>
        )
    else
        return (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-300 text-yellow-700">
                {auslastung}
            </p>
        )
}