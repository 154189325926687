import * as React from "react";
import {CheckIcon} from "@heroicons/react/solid";
import Auslastung from "../UeberMich/Auslastung";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Projektdetails({data}) {

    return (

        <section aria-labelledby="projekt-details">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="projekt-titel" className="text-lg leading-6 font-medium text-gray-900">
                        {data.Headline1}
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{data.Headline2}</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Dauer</dt>
                            <dd className="mt-1 text-sm font-bold text-gray-900">{data.Dauer}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Einsatzort</dt>
                            <dd className="mt-1 text-sm font-bold text-gray-900">{data.Einsatzort}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Projektgröße</dt>
                            <dd className="mt-1 text-sm font-bold text-gray-900">{data.Projektgroesse}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Auslastung</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <Auslastung auslastung={data.Auslastung}/>
                            </dd>
                        </div>


                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Projektinhalte</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <ul role="list">
                                    {data.Projektinhalte.map((feature, featureIdx) => (
                                        <li key={feature}
                                            className={classNames(featureIdx === 0 ? 'md:py-0 ' : '', ' flex')}>
                                            <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500"
                                                       aria-hidden="true"/>
                                            <span className="ml-3 text-sm text-gray-500">{feature}</span>
                                        </li>
                                    ))}
                                </ul>


                            </dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Eingesetzte Technologien</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <ul role="list">
                                    {data.Technologien.map((technologie, featureIdx) => (
                                        <li key={technologie}
                                            className={classNames(featureIdx === 0 ? 'md:py-0 ' : '', ' flex')}>
                                            <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500"
                                                       aria-hidden="true"/>
                                            <span className="ml-3 text-sm text-gray-500">{technologie}</span>
                                        </li>
                                    ))}
                                </ul>


                            </dd>
                        </div>
                    </dl>
                </div>

            </div>
        </section>
    )
}
