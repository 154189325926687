import * as React from "react";

export default function Herausforderungen({Herausforderungen}) {
    return (
        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                    Herausforderungen
                </h2>
                <div className="mt-6 text-sm flex flex-col gap-y-4">
                    {Herausforderungen.map((herausforderung, index) => (
                        <p key={"her_"+index} className="flex-item">
                            {herausforderung}
                        </p>
                    ))}
                </div>
            </div>
        </section>
    )
}