import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BreadCrumbs from "../components/BreadCrumbs";
import Projektdetails from "../components/Projekte/Projektdetails";
import ProjektdetailHeader from "../components/Projekte/ProjektdetailHeader";
import Herausforderungen from "../components/Projekte/Herausforderungen";

const projektTemplate = props => {
    const { pageContext } = props
    const { Projekt } = pageContext

    return (
        <Layout>
            <Seo title={Projekt.SeoTitel} />
            <BreadCrumbs pages={Projekt.Breadcrumbs}/>
            <div className="min-h-full">
                <main className="py-10">
                    <ProjektdetailHeader Endkunde={Projekt.Endkunde} Kurzbeschreibung={Projekt.Kurzbeschreibung} />

                    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            <Projektdetails data={Projekt}/>
                        </div>

                        <Herausforderungen Herausforderungen={Projekt.Herausforderungen} />
                    </div>
                </main>
            </div>
        </Layout>
    )
}
export default projektTemplate